<template>
  <div class="container">
    <div class="modal-header">
      <h4 class="modal-title">{{ type === 'horse' ? item.horse_name : type === 'service' ? item.business_name : item.listing_name }}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12">
        <a-carousel arrows dots-class="slick-dots slick-thumb" :after-change="onCarouselChange" :beforeChange="beforeCarouselChange">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; z-index: 1000">
              <left-circle-outlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <right-circle-outlined />
            </div>
          </template>
          <template #customPaging="props">
            <a>
              <img v-if="item.images && props.i < item.images.length" :src="item.images[props.i]" />
              <div v-if="item.images && props.i === item.images.length">
                <div class="video-text">Video</div>
                <PlayCircleFilled :style="{ fontSize: '24px', color: '#ccc' }" />
              </div>
            </a>
          </template>
          <template v-if="Array.isArray(item.images)">
            <div v-for="image in item.images" :key="image">
              <div style="position:relative; height: 400px;">
                <img :src="image" style="width: 100%; height: 400px; object-fit: contain" />
              </div>
            </div>
          </template>
          <div v-if="Array.isArray(item.images) === false || item.images.length === 0">
            <div style="position:relative;">
              <img src="../assets/img/placeholder_logo.png" style="width: 100%; height: 400px; object-fit: contain; border: none; padding: 16px;" />
            </div>
          </div>
          <template v-if="item.video_url != null && item.video_url !== ''">
            <div class="video-container">
              <video id="player" class="player" style="--plyr-color-main: #1ac266;"></video>
              <div class="video-text">Video</div>
            </div>
          </template>
        </a-carousel>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12 col-sm-12" v-if="type === 'horse'">
        <h4 class="price" v-if="item.for_sale_status === SaleStatus.WithPrice || item.price_must_contact === false">Price: {{ preview ? item.price : formatPrice(item.price) }}</h4>
        <h4 class="price" v-if="item.for_sale_status === SaleStatus.WithContact || item.price_must_contact">Contact Seller for Price</h4>
        <h4 class="price" v-if="item.for_sale_status === SaleStatus.NotAvailable">For Lease</h4>
        <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithPrice" style="margin-top: -5px; margin-bottom: 5px; font-size: 16px; font-weight: bold; color: gray;">Lease for {{ preview ? item.lease_price : formatPrice(item.lease_price) }}/year</div>
        <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithContact" style="margin-top: -5px; margin-bottom: 5px; font-size: 16px; font-weight: bold; color: gray;">Contact seller for lease price</div>
        <div style="display: flex; justify-content: space-between">
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openContactItem">
                <a-menu-item key="email" v-if="item.allowEmail === undefined || item.allowEmail">Email</a-menu-item>
                <a-menu-item key="phone" v-if="item.allowCall === undefined || item.allowCall">Phone</a-menu-item>
                <a-menu-item key="text" v-if="item.allowText === undefined || item.allowText && !item.allowCall">Text</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 58%;" @click="e => e.preventDefault()">Contact Seller</a-button>
          </a-dropdown>
          <a-button type="primary" style="width: 20%;" @click="toggleFavorite();">
            <span v-if="isFavorited() === false" style="display: flex; align-items: center; justify-content: center"><HeartOutlined /></span>
            <span v-if="isFavorited()" style="display: flex; align-items: center; justify-content: center"><HeartFilled /></span>
          </a-button>
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openShareLink">
                <a-menu-item key="facebook" style="display: flex; align-items: center"><FacebookFilled /> Facebook</a-menu-item>
                <a-menu-item key="email" style="display: flex; align-items: center"><MailFilled /> Email</a-menu-item>
                <a-menu-item key="copy" style="display: flex; align-items: center"><CopyFilled /> Copy Link</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 20%; display: flex; align-items: center; justify-content: center" @click="e => e.preventDefault()">
              <ShareAltOutlined />
            </a-button>
          </a-dropdown>
        </div>
        <table class="detail-table">
          <tr v-if="item.address.hide === false">
            <th>Address</th>
            <td>{{item.address.address}}</td>
          </tr>
          <tr v-if="item.address.hide === true">
            <th>Address</th>
            <td>{{item.address.city}}, {{item.address.state}}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{{item.horse_name}}</td>
          </tr>
          <tr>
            <th>Age</th>
            <td>{{item.age}} {{item.age_unit === 'MONTH' ? 'months' : 'years'}} old</td>
          </tr>
          <tr>
            <th>Breed</th>
            <td v-if="Array.isArray(item.breed)">{{item.breed.join(', ')}}</td>
          </tr>
          <tr>
            <th>Sex</th>
            <td v-if="Array.isArray(item.sex)">{{item.sex.join(', ')}}</td>
          </tr>
          <tr>
            <th>Discipline</th>
            <td v-if="Array.isArray(item.discipline)">{{item.discipline.join(', ')}}</td>
          </tr>
          <tr v-if="item.dam != null">
            <th>Dam</th>
            <td>{{item.dam}}</td>
          </tr>
          <tr v-if="item.sire != null">
            <th>Sire</th>
            <td>{{item.sire}}</td>
          </tr>
          <tr>
            <th>Height</th>
            <td>{{item.height}}hh</td>
          </tr>
          <tr>
            <th>Color</th>
            <td v-if="Array.isArray(item.color)">{{item.color.join(', ')}}</td>
          </tr>
          <tr v-if="item.website != null">
            <th>Website</th>
            <td><a :href="getCorrectedLink(item.website)" target="_blank">{{item.website}}</a></td>
          </tr>
          <tr>
            <th>Contact Name</th>
            <td>{{item.contact.name}}</td>
          </tr>
        </table>
      </div>
      <div class="col-lg-4 col-xs-12 col-sm-12 col-md-12" style="margin-top: 10px;" v-if="type === 'service'">
        <div style="display: flex; justify-content: space-between">
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openContactItem">
                <a-menu-item key="email" v-if="item.allowEmail === undefined || item.allowEmail">Email</a-menu-item>
                <a-menu-item key="phone" v-if="item.allowCall === undefined || item.allowCall">Phone</a-menu-item>
                <a-menu-item key="text" v-if="item.allowText === undefined || item.allowText && !item.allowCall">Text</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 58%;" @click="e => e.preventDefault()">Contact Provider</a-button>
          </a-dropdown>
          <a-button type="primary" style="width: 20%;" @click="toggleFavorite();">
            <span v-if="isFavorited() === false" style="display: flex; align-items: center; justify-content: center"><HeartOutlined /></span>
            <span v-if="isFavorited()" style="display: flex; align-items: center; justify-content: center"><HeartFilled /></span>
          </a-button>
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openShareLink">
                <a-menu-item key="facebook" style="display: flex; align-items: center"><FacebookFilled /> Facebook</a-menu-item>
                <a-menu-item key="email" style="display: flex; align-items: center"><MailFilled /> Email</a-menu-item>
                <a-menu-item key="copy" style="display: flex; align-items: center"><CopyFilled /> Copy Link</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 20%; display: flex; align-items: center; justify-content: center" @click="e => e.preventDefault()">
              <ShareAltOutlined />
            </a-button>
          </a-dropdown>
        </div>
        <table class="detail-table">
          <tr v-if="item.address.hide === false">
            <th>Address</th>
            <td>{{item.address.address}}</td>
          </tr>
          <tr v-if="item.address.hide === true">
            <th>Address</th>
            <td>{{item.address.city}}, {{item.address.state}}</td>
          </tr>
          <tr>
            <th>Service Type</th>
            <td>{{item.type}}</td>
          </tr>
          <tr>
            <th>Business Name</th>
            <td>{{item.business_name}}</td>
          </tr>
          <tr>
            <th>Social Media</th>
            <td>{{item.social_media}}</td>
          </tr>
          <tr>
            <th>Website</th>
            <td><a :href="getCorrectedLink(item.website)" target="_blank">{{item.website}}</a></td>
          </tr>
          <tr>
            <th>Contact Name</th>
            <td>{{item.contact.name}}</td>
          </tr>
        </table>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12 col-sm-12" style="margin-top: 10px;" v-if="type === 'equipment'">
        <div style="border: 1px solid #ddd; padding: 4px 8px; font-size: 12px;">Mane Street Market is not responsible for the quality of the items advertised. Buyers must do their due diligence in confirming quality with seller.</div>
        <h4 class="price">Price: {{ item.price ? formatPrice(item.price) : '-' }}</h4>
        <div style="display: flex; justify-content: space-between">
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openContactItem">
                <a-menu-item key="email" v-if="item.allowEmail === undefined || item.allowEmail">Email</a-menu-item>
                <a-menu-item key="phone" v-if="item.allowCall === undefined || item.allowCall">Phone</a-menu-item>
                <a-menu-item key="text" v-if="item.allowText === undefined || item.allowText && !item.allowCall">Text</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 58%;" @click="e => e.preventDefault()">Contact Seller</a-button>
          </a-dropdown>
          <a-button type="primary" style="width: 20%;" @click="toggleFavorite();">
            <span v-if="isFavorited() === false" style="display: flex; align-items: center; justify-content: center"><HeartOutlined /></span>
            <span v-if="isFavorited()" style="display: flex; align-items: center; justify-content: center"><HeartFilled /></span>
          </a-button>
          <a-dropdown :trigger="['click']">
            <template #overlay>
              <a-menu @click="openShareLink">
                <a-menu-item key="facebook" style="display: flex; align-items: center"><FacebookFilled /> Facebook</a-menu-item>
                <a-menu-item key="email" style="display: flex; align-items: center"><MailFilled /> Email</a-menu-item>
                <a-menu-item key="copy" style="display: flex; align-items: center"><CopyFilled /> Copy Link</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" style="width: 20%; display: flex; align-items: center; justify-content: center" @click="e => e.preventDefault()">
              <ShareAltOutlined />
            </a-button>
          </a-dropdown>
        </div>
        <table class="detail-table">
          <tr v-if="item.address.hide === false">
            <th>Address</th>
            <td>{{item.address.address}}</td>
          </tr>
          <tr v-if="item.address.hide === true">
            <th>Address</th>
            <td>{{item.address.city}}, {{item.address.state}}</td>
          </tr>
          <tr>
            <th>Equipment Type</th>
            <td>{{item.type}}</td>
          </tr>
          <tr>
            <th>Condition of Item</th>
            <td>{{item.condition}}{{item.condition === 'Used' && item.condition_years ? ` - ${item.condition_years} year(s)` : ''}}</td>
          </tr>
          <tr v-if="item.brand != null">
            <th>Brand</th>
            <td>{{item.brand}}</td>
          </tr>
          <tr v-if="item.size != null">
            <th>Size</th>
            <td>{{item.size}}</td>
          </tr>
          <tr v-if="item.discipline != null">
            <th>Discipline</th>
            <td>{{item.discipline}}</td>
          </tr>
          <tr v-if="item.model != null">
            <th>Model</th>
            <td>{{item.model}}</td>
          </tr>
          <tr v-if="item.shipping != null">
            <th>Shipping Details</th>
            <td>{{item.shipping}}</td>
          </tr>
          <tr>
            <th>Contact Name</th>
            <td>{{item.contact.name}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Description</strong>
        <p>{{item.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {LeftCircleOutlined, RightCircleOutlined, HeartOutlined, HeartFilled, ShareAltOutlined, FacebookFilled, TwitterCircleFilled, MailFilled, PlayCircleFilled, CopyFilled} from '@ant-design/icons-vue';
import ACarousel from 'ant-design-vue/lib/carousel';
import AButton from 'ant-design-vue/lib/button';
import ADropdown from 'ant-design-vue/lib/dropdown';
import AMenu from 'ant-design-vue/lib/menu';
import AModal from 'ant-design-vue/lib/modal';
import { message } from 'ant-design-vue';
import 'ant-design-vue/lib/carousel/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/dropdown/style/index.css';
import 'ant-design-vue/lib/menu/style/index.css';
import 'ant-design-vue/lib/modal/style/index.css';
import 'ant-design-vue/lib/message/style/index.css';
import $ from "jquery";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { SaleStatus } from '@/enums/sale_status';
import Swal from "sweetalert2";

export default {
  components: {
    ACarousel,
    AButton,
    ADropdown,
    AMenu,
    AModal,
    'a-menu-item': AMenu.Item,
    LeftCircleOutlined,
    RightCircleOutlined,
    HeartOutlined,
    HeartFilled,
    ShareAltOutlined,
    FacebookFilled,
    TwitterCircleFilled,
    MailFilled,
    PlayCircleFilled,
    CopyFilled
  },
  props: {
    item: Object,
    preview: {
      type: Boolean,
      default: false
    },
    type: String
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
      SaleStatus,
      player: null
    }
  },
  methods: {
    formatPrice(value) {
      return this.formatter.format(value);
    },
    openContactItem({key}) {
      if (this.preview) {
        return;
      }
      if (this.$store.state.token == null) {
        Swal.fire({
          text: 'To "Contact Seller," please sign in to your account. If you don\'t have an account, please create an account and sign in with user name and password.',
        }).then(res => {
          if (res.isConfirmed) {
            $('#loginModal').modal('show');
            $('#myTab a[href="#sign-in-content"]').tab('show');
          }
        });
        return;
      }
      if (key === 'email') {
        window.open('mailto:' + this.item.contact.email);
      } else if (key === 'phone') {
        AModal.info({
          content: `Phone Number: ${ this.item.contact.phone }`,
          zIndex: 1100
        });
      } else if (key === 'text') {
        AModal.info({
          content: `Phone Number: ${ this.item.contact.phone } (Please Text - No Calls)`,
          zIndex: 1100
        });
      }
    },
    openShareLink({key}) {
      if (this.preview) {
        return;
      }

      let url, targetUrl, encodedUrl;
      if (this.type === 'horse') {
        targetUrl = `${ process.env.VUE_APP_WEB_URL }/search?id=${ this.item.id }`;
      } else if (this.type === 'service') {
        targetUrl = `${ process.env.VUE_APP_WEB_URL }/search-service-providers?type=Any&id=${ this.item.id }`;
      } else if (this.type === 'equipment') {
        targetUrl = `${ process.env.VUE_APP_WEB_URL }/search-equipment?type=Any&id=${ this.item.id }`;
      } else {
        targetUrl = window.location.href;
      }
      encodedUrl = encodeURIComponent(targetUrl);

      let title = this.type === 'horse' ? this.item.horse_name : this.type === 'service' ? this.item.business_name : this.item.listing_name;
      if (key === 'twitter') {
        url = `https://twitter.com/share?url=${encodedUrl}&text=${title}`;
      } else if (key === 'email' && this.type === 'horse') {
        url = `mailto:?subject=Found a great horse on ManeStreetMarket.com&body=${encodedUrl}`;
      } else if (key === 'email' && this.type === 'service' || this.type === 'equipment') {
        url = `mailto:?subject=${title}&body=${encodedUrl}`;
      } else {
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${title}`;
      }

      if (key !== 'copy') {
        window.open(url, '_blank');
      } else {
        const el = document.createElement('textarea');
        el.value = targetUrl;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected = document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
        el.select();
        let copySuccessful = document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }
        if (copySuccessful) {
          message.success('Link copied');
        }
      }
    },
    toggleFavorite() {
      if (this.preview) {
        return;
      }
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      if (this.type === 'horse') {
        this.$store.dispatch('toggleFavoriteHorse', this.item.id);
      } else if (this.type === 'service') {
        this.$store.dispatch('toggleFavoriteService', this.item.id);
      } else if (this.type === 'equipment') {
        this.$store.dispatch('toggleFavoriteEquipment', this.item.id);
      }
    },
    isFavorited() {
      if (this.type === 'horse') {
        return this.$store.state.favorite_horses.indexOf(this.item.id) > -1;
      } else if (this.type === 'service') {
        return this.$store.state.favorite_services.indexOf(this.item.id) > -1;
      } else if (this.type === 'equipment') {
        return this.$store.state.favorite_equipments.indexOf(this.item.id) > -1;
      }
    },
    onCarouselChange(i) {
      if (i === this.item.images.length) {
        if (this.player == null) {
          this.initializePlayer();
          setTimeout(() => this.initializePlayer(), 500); // for some reason initialization needs to be done twice
        }
      } else {
        if (this.player != null) {
          this.player.pause();
        }
      }
    },
    beforeCarouselChange(from, to) {
      if (from === this.item.images.length) {
        if (this.player != null) {
          this.player.pause();
        }
      }
    },
    initializePlayer() {
      if (this.item.video_url == null) {
        return;
      }
      let videoUrl = this.item.video_url;
      let provider = this.item.video_url.indexOf('youtube') > -1 || this.item.video_url.indexOf('youtu.be') > -1 ? 'youtube' : 'vimeo';
      this.player = new Plyr('#player');
      this.player.source = {
        type: 'video',
        sources: [
          {
            src: videoUrl,
            provider: provider,
          },
        ],
      };

      this.player.on('play', e => {
        let elems = document.getElementsByClassName('video-text');
        for (let elem of elems) {
          elem.style.display = 'none';
        }
      });

      this.player.on('pause', e => {
        let elems = document.getElementsByClassName('video-text');
        for (let elem of elems) {
          elem.style.display = 'block';
        }
      })
    },
    getCorrectedLink(url) {
      if (typeof url === 'string' || url instanceof String) {
        if (url.startsWith('http')) {
          return url;
        } else {
          return `http://${ url }`;
        }
      }
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>

.price {
  margin-top: 10px;
}

.detail-table {
  margin-top: 10px;
  border: 1px solid #ccc;

  tr {
    border-bottom: 1px solid #ccc;

    th {
      background: #ddd;
      padding: 4px;
      vertical-align: top;
      font-size: 12px;
      width: 140px;
    }

    td {
      padding: 4px;
      font-size: 12px;
    }
  }
}

.ant-carousel ::v-deep(.slick-slide) {
  text-align: center;
  overflow: hidden;
}

.ant-carousel ::v-deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 1;
}
.ant-carousel ::v-deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel ::v-deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel ::v-deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel ::v-deep(.slick-dots) {
  position: relative;
  height: auto;
}
.ant-carousel ::v-deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 100%;
}

.ant-carousel ::v-deep(.slick-thumb) {
  bottom: 0px;
}
.ant-carousel ::v-deep(.slick-thumb li) {
  width: 60px;
  height: 45px;
}
.ant-carousel ::v-deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel ::v-deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.ant-carousel ::v-deep .plyr {
  height: 400px;
  background: white;
}

.video-container {
  position: relative;

  .video-text {
    position: absolute;
    font-weight: 500;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: black;
    padding: 2px 8px;
  }
}

.slick-dots {
  .video-text {
    font-size: 12px;
    font-weight: bold;
    user-select: none;
  }
}

</style>
